.noteCard {
    background-color: lightgreen;
}

.cardBody {
    overflow: hidden;
    mask-image: linear-gradient(180deg, #000 60%, transparent);
}

.cardText {
    white-space: pre-line;
}