.notesPage {
    all: initial;
    align-items: center;
}

.notesGrid {
    display: flex;
    flex-direction: table-column;
    width: 100%;
}

.note {
    height: 200px;
    min-width: 150px;
    transition: box-shadow .1s ease-in-out;
    cursor: pointer;
}

.note:hover {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}
