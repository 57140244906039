.mcpackCard {
    background-color: lightgray;
}

.cardBody {
    overflow: hidden;
    mask-image: linear-gradient(180deg, #000 60%, transparent);
}

.cardText {
    white-space: pre-line;
    text-align: center
}

.mcpackPage {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo {
    max-width: 100%;
    height: auto;
    margin-top: 2rem;
    margin-bottom: 2rem;
}
.cardTitle {
    text-align: center;
    display: block;
    margin-bottom: 0.5rem;
  }
  
  .subheading {
    font-size: 14px;
    text-align: center;
    display: block;
  }