.TexturePackCreatorCard {
  background-color: lightgray;
}

.cardBody {
  overflow: hidden;
  mask-image: linear-gradient(180deg, #000 60%, transparent);
}

.cardText {
  white-space: pre-line;
  text-align: center
}

.TexturePackCreatorPage {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo {
  max-width: 100%;
  height: auto;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.cardTitle {
  text-align: center;
  display: block;
  margin-bottom: 0.5rem;
}

.subheading {
  font-size: 14px;
  text-align: center;
  display: block;
}

.textureCard {
  border: 3px solid transparent;
  transition: all 0.2s ease-in-out;
}

.textureCard.selected {
  border-color: blue;
}
